import React from 'react'

const Header = (props) => (
  <header id="header" className="alt">
    <h1>Carien Zeeman</h1>
    <p>Social Worker in Private Practice</p>
  </header>
)

export default Header
